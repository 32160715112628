import history from "src/routes/history";
import { clearLog } from "src/redux/slices/login";
import { store } from 'src/redux/store';

export default function errors(error) {

    // const state = store.getState();
    console.log(error.response.status);

    if(error.response.status === 401){
        store.dispatch(clearLog());
        localStorage.removeItem('email');
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.removeItem('role');        
        history.push('/login');
    } else if(error.response.status === 404) {
        history.push('/404');
    }

}