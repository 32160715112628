import { call, put } from "redux-saga/effects";
import { setSingle } from "../../slices/single";
import requestgetSingle from "../request/single";
import errors from "src/redux/saga/errors";

export function* handleSingle(action) {
    try {
        const { payload } = action;
        const response = yield call(requestgetSingle, payload);
        const { data } = response;
        yield put(setSingle({post: data, }));
    } catch (error){
        errors(error);
        console.log(error);        
    }
}
