import axios from "axios";

export default function requestCreatePropertyval(endpoint){

    const {pagename, pageid, type, id, data } = endpoint;

    /*
    * add the param of the type of the move inside the data
    * inventory_move_code: add_quantity/remove_quantity/selling/add_trial/remove_trial
    */

    //data['inventory_move_code'] = movecode;

    const propValue = (values) => {
        for (let val in values) {   
          if(values[val] === true || values[val] === false) {
            if(values[val] === true) {
              values[val] = 'true'
            }else{
              values[val] = 'false'
            }
          }
        }
        return values;
    };  

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    //backoffice/:lang/product/:productid/version/:id

    return axios.put(process.env.REACT_APP_API_URL+'/it/'+pagename+'/'+pageid+'/'+type+'/'+id, propValue(data), config);
}