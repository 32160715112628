import React from 'react';
import { PATH_APP } from 'src/routes/paths';
import { MIcon } from 'src/theme';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  blog: <MIcon src={path('ic_blog')} />,
  calendar: <MIcon src={path('ic_calendar')} />,
  cart: <MIcon src={path('ic_cart')} />,
  charts: <MIcon src={path('ic_charts')} />,
  chat: <MIcon src={path('ic_chat')} />,
  components: <MIcon src={path('ic_components')} />,
  dashboard: <MIcon src={path('ic_dashboard')} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={path('ic_elements')} />,
  error: <MIcon src={path('ic_error')} />,
  mail: <MIcon src={path('ic_mail')} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={path('ic_page')} />,
  user: <MIcon src={path('ic_user')} />,
  upload: <MIcon src={path('ic_upload')} />,
  copy: <MIcon src={path('ic_copy')} />,
  carousel: <MIcon src={path('ic_carousel')} />,
  language: <MIcon src={path('ic_language')} />
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        icon: ICONS.dashboard,
        href: PATH_APP.general.root,
        items: [
          {
            title: 'analytics',
            href: PATH_APP.general.root
          },
          {
            title: 'e-commerce',
            href: PATH_APP.general.ecommerce
          },
          // {
          //   title: 'analytics',
          //   href: PATH_APP.general.analytics
          // }
        ]
      }
    ]
  },

  // SETTING
  // ----------------------------------------------------------------------
  {
    subheader: 'setting',
    items: [
      {
        title: 'configurations',
        icon: ICONS.carousel,
        href: PATH_APP.app.root,
        items: [
          {
            title: 'tracking',
            href: PATH_APP.app.tracking
          },          
          {
            title: 'language',
            href: PATH_APP.app.language
          },
          {
            title: 'users',
            href: PATH_APP.app.user
          },
          {
            title: 'template',
            href: PATH_APP.app.template
          },
          {
            title: 'property',
            href: PATH_APP.app.property
          },
          {
            title: 'forms',
            href: PATH_APP.app.fillformconfiguration
          }
        ]
      }
    ]
  },

  // WAREHOUSE
  // ----------------------------------------------------------------------
  {
    subheader: 'warehouse',
    items: [
      {
        title: 'warehouse',
        icon: ICONS.elements,
        href: PATH_APP.warehouse.root,
        items: [
          {
            title: 'import',
            href: PATH_APP.warehouse.import
          },
          {
            title: 'search',
            href: PATH_APP.warehouse.search
          },
          {
            title: 'barcodes',
            href: PATH_APP.warehouse.barcodes
          },
          {
            title: 'storage',
            href: PATH_APP.warehouse.warehouse
          }                              
        ]
      },
    ]
  },  

  // ECOMMERCE
  // ----------------------------------------------------------------------
  {
    subheader: 'ecommerce',
    items: [
      {
        title: 'Pages',
        href: PATH_APP.app.page,
        icon: ICONS.page
      },
      {
        title: 'Categories',
        href: PATH_APP.app.category,
        icon: ICONS.components
      },
      {
        title: 'Brands',
        href: PATH_APP.app.brand,
        icon: ICONS.blog
      },
      {
        title: 'Products',
        href: PATH_APP.app.product,
        icon: ICONS.authenticator
      },
      {
        title: 'Discounts',
        href: PATH_APP.app.discount,
        icon: ICONS.error
      },
      {
        title: 'Orders',
        href: PATH_APP.app.order,
        icon: ICONS.cart
      }      
    ]
  }
];

export default navConfig;
