import { call, put } from "redux-saga/effects";
import { updateSingle, setError } from "../../slices/single";
import requestCreateSingle from "../request/createsingle"
import history from "src/routes/history";
import errors from "src/redux/saga/errors"

export function* handleCreateSingle(action) {
    try {
        const { payload } = action;
        const { id } = payload;
        const response = yield call(requestCreateSingle, payload);
        const { data } = response;
        yield put(updateSingle(data));
        if(id === 0){
            history.push(data[0].value);
        }
    } catch (error){
        yield put(setError());
        console.log(error);
        errors(error);
    }
}