import { createSlice } from '@reduxjs/toolkit';

export const listingSlice = createSlice({
  name: 'listing',
  initialState: null,
  reducers: {
    getListing() {},
    setListing(state, action) {      
      const listingData = action.payload;
      return {...state, ...listingData}
    }
  }
})

export const { getListing, setListing } = listingSlice.actions;

export default listingSlice.reducer;