import clsx from 'clsx';
import React, { useRef, useState, useEffect } from 'react';
import PopoverMenu from 'src/components/PopoverMenu';
import { makeStyles } from '@material-ui/core/styles';
import { Box, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { MIconButton } from 'src/theme';
import axios from "axios";
import handlerErrors from "src/utils/errors";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  menuItem: {
    padding: theme.spacing(1, 2.5)
  },
  btnLang: {
    padding: 0,
    width: 44,
    height: 44
  },
  isSelected: {
    backgroundColor: theme.palette.background.selected
  }
}));

// ----------------------------------------------------------------------

function Languages() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [list, setList] = useState(null);

  const getlangs = async () => {
    const data = {
      "searchterm": "",
      "pagesize": 10000
    };

    const config = {
      headers: {
        "x-token": localStorage.getItem('token'),
        "Content-Type": "application/json"
      }
    };    
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL+'/it/language', data, config);
      const resData = await response;
      if (resData.status === 200) {
        //console.log(resData.items);
        setList(resData.data);
      } else {      
        handlerErrors(resData);
      }
    } catch(err) {
      handlerErrors(err);
    }
  }   

  useEffect(() => {             
    getlangs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  if(list){
    const langs = list.items.map(e => {
      return e.filter(i => {
        if(i.id == 'name' || i.id == 'code'){
          return i;
        }
      });
    }).map(e => {
      return e.reduce((o, key) => ({...o, [key.id]: key.value}), {});
    });

    return (
      <>
        <MIconButton
          ref={anchorRef}
          onClick={() => setOpen(true)}
          className={clsx(classes.btnLang, { [classes.isSelected]: isOpen })}
        >
          <img src={'/static/icons/ic_flag_'+langs[0].code+'.svg'} />
        </MIconButton>
  
        <PopoverMenu
          width={200}
          open={isOpen}
          onClose={() => setOpen(false)}
          anchorEl={anchorRef.current}
        >
          <Box sx={{ py: 1 }}>
            {langs.map((option, index) => (
              <MenuItem
                key={index}
                selected={option.code === langs[0].code}
                onClick={() => {}}
                className={classes.menuItem}
              >
                <ListItemIcon>
                  <Box component="img" alt={option.name} src={'/static/icons/ic_flag_'+option.code+'.svg'} />
                </ListItemIcon>
  
                <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                  {option.name}
                </ListItemText>
              </MenuItem>
            ))}
          </Box>
        </PopoverMenu>
      </>
    );    
  } else {
    return null
  }
}

export default Languages;
