import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import settingsReducer from './slices/settings';
import counterSlice from "./slices/counter";
import loginSlice from "./slices/login";
import listingSlice from "./slices/listing";
import singlegSlice from "./slices/single";
import propertySlice from "./slices/postlist";
import propertyvalSlice from "./slices/postlistvalue";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings', 'listing', 'single', 'property', 'propertyval']
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  listing: listingSlice,
  single: singlegSlice,
  property: propertySlice,
  propertyval: propertyvalSlice,
  counter: counterSlice,
  login: loginSlice
});

export { rootPersistConfig, rootReducer };
