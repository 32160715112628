import axios from "axios";

export default function requestgetSingle(endpoint){

    const { id, title } = endpoint;

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    return axios.get(process.env.REACT_APP_API_URL+"/it/"+title+"/"+id, config);
}